.table:hover{
    background-color: rgba(21, 101, 192, 0.04);


}

.table .number:hover {
    background-color: rgba(21, 101, 192, 0.04);
}

tr{
    margin: 20px;
    cursor: pointer;
}

.orange {
    color: orange;
    text-transform: capitalize;
  }

  .no-bottom-border {
    border-bottom: none !important;
  }