html, body {
  height: 100vh;
  background-color: #F6F9FB;
  margin: 0;
  font-family: 'Nunito' ;
  text-transform: none !important;
  
}


code {
  font-family: Nunito;
}

.MuiButtonBase-root .MuiButton-root{
  text-transform: capitalize;
}


.MuiFormControl-root .MuiFormLabel-root{
  color:rgba(0, 0, 0, 0.54);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.079px;
  text-transform: none;
  }

  .MuiButtonBase-root .MuiChip-root{
    background-color:rgba(0, 0, 0, 0.54);
  }
  .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.079px;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87)
  }

  a{
    text-decoration: none;
    color: inherit;
  }
li:hover{
  background-color: "rgba(21, 101, 192, 0.04)"
}

li#checkboxes-tags-demo-option {
  font-family: Nunito;
  background-color: rgba(21, 101, 192, 0.04);

}
.no-bottom-border {
  border-bottom: none !important;
}

