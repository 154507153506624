.cr_domain, .cr_domain:focus{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

.cr_domain:focus {
    outline: none;
    border-bottom: 2px solid #1565C0;
}

.ateco{
    margin-top: 25px;
}

.submitbutton{
    display: flex;
    width: fit-content;
    text-transform: capitalize;
}


.error{
font-size: 14px;
color: #B71C1C;
background: #FBE4E4;
display: flex;
align-items: center;
padding: 10px;
gap: 5px;
border: 2px solid #B71C1C;
}

.prosses{
color: rgba(30, 136, 229, 1);
background: #F6F9FB;;
display: flex;
align-items: center;
justify-content: left;
padding: 7px 15px;
border: 1px solid rgba(30, 136, 229, 1);
border-radius: 5px;
}

.prosses .icon {
    color:  rgba(30, 136, 229, 1);
}

.prosses a {
    text-decoration: underline;
    font-weight: 600;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.job_addbutton__cbbvd.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
    font-size: 14px;
    text-transform: capitalize;
}

.list :hover {
    background-color: red;
  
  }

