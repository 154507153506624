.menu a:hover {
    background: rgba(21, 101, 192, 0.04);
    color: rgba(30, 136, 229, 1)  ;  
    border-bottom: 1px solid rgba(30, 136, 229, 1);
    border-radius: 4px, 4px, 0px, 0px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    
;}

.menu a {
padding: 14px 15px;
display: flex;
flex-wrap: wrap;
align-content: center;

}